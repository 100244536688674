import React, { useEffect, useRef } from 'react';
// import { Helmet } from 'react-helmet';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { Route, Routes, useNavigate } from 'react-router-dom';

import PublicRoute from 'widgets/PublicRoute';
import PrivateRoute from 'widgets/PrivateRoute';
import AuthSimpleLayout from 'layouts/AuthSimpleLayout';
import LoadingCenter from 'components/loading/LoadingCenter';

const OTP = React.lazy(() => import('./screens/OTP'));
const Login = React.lazy(() => import('./screens/Login'));
const Layout = React.lazy(() => import('./layouts/Layout'));
const ResetPassword = React.lazy(() => import('./screens/ResetPassword'));
const ForgotPassword = React.lazy(() => import('./screens/ForgotPassword'));

function AppBody() {
  const _isMounted = useRef(false);
  const navigate = useNavigate();
  const settings = useSelector(state => state.settings);
  const { currentModule } = useSelector(state => state.theme);
  const { title, favicon_16ISfile, favicon_32ISfile, favicon_192ISfile, env_files } = settings || {};
  const auth = useSelector(state => state.auth);

  const className = 'backend';
  const addBodyClass = name => document.body.classList.add(name);
  const removeBodyClass = name => document.body.classList.remove(name);

  const loadScript = src => {
    let bodyEle = document.getElementsByTagName('body');
    let tag = document.createElement('script');
    tag.async = false;
    tag.src = src;
    bodyEle[0].appendChild(tag);
  };
  useEffect(() => {
    const onStorageChange = event => {
      if (event.key === 'logout') {
        if (!document.hidden) {
          console.log('Active tab. Doesn`n it close()');
        } else {
          // window.close();
          window.location.reload();
          navigate('/login');
        }
      }
    };

    window.addEventListener('storage', onStorageChange);

    return () => {
      window.removeEventListener('storage', onStorageChange);
    };
  }, []);

  useEffect(() => {
    _isMounted.current = true;

    if (env_files?.page_bottom && env_files?.page_bottom.length > 0) {
      env_files?.page_bottom.forEach(item => {
        loadScript(item.url);
      });
    }
    return () => {
      _isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    _isMounted.current = true;
    if (auth.isAuthenticated) {
      addBodyClass(className);
    } else {
      removeBodyClass(className);
    }

    return () => {
      _isMounted.current = false;
    };
  }, [auth.isAuthenticated]);

  return (
    <>
      <div className="wrapper">
        <HelmetProvider>
          <Helmet>
            <title>{`${title}${currentModule && ` - ${currentModule}`}`}</title>
            {favicon_16ISfile?.uri && (
              <link rel="icon" type={favicon_16ISfile.type || 'image/png'} href={favicon_16ISfile.uri} sizes="16x16" />
            )}
            {favicon_32ISfile?.uri && (
              <link rel="icon" type={favicon_32ISfile.type || 'image/png'} href={favicon_32ISfile.uri} sizes="32x32" />
            )}
            {favicon_192ISfile?.uri && (
              <link
                rel="icon"
                type={favicon_192ISfile.type || 'image/png'}
                href={favicon_192ISfile.uri}
                sizes="192x192"
              />
            )}
            {env_files?.page_top &&
              env_files?.page_top.length > 0 &&
              env_files?.page_top.map((item, index) => <link rel="stylesheet" href={item.url} key={index} />)}
          </Helmet>
        </HelmetProvider>
        <React.Suspense fallback={<LoadingCenter simple={true} msg="Loading..." msgColor="inherit" />}>
          <Routes>
            {/* Public */}
            <Route element={<AuthSimpleLayout />}>
              <Route element={<PublicRoute />}>
                <Route exact path="login" name="Login" element={<Login />} />
                <Route exact path="otp" name="OTP" element={<OTP />} />
                <Route exact path="forgot-password" name="Request Password Reset Link" element={<ForgotPassword />} />
              </Route>

              <Route exact path="reset-password" name="Password Reset" element={<ResetPassword />} />
            </Route>

            {/* Private */}
            <Route element={<PrivateRoute />}>
              <Route name="Home" path="*" element={<Layout />} />
            </Route>
          </Routes>
        </React.Suspense>
      </div>
    </>
  );
}

export default AppBody;
